export const thresholdNormalizedBuffer = <T extends { value: number, normalizedValue }>(max: number) => {



    const insert = (elements: T[]) => {

        const o = elements.sort((a, b) => b.value - a.value).slice(0, max);
        const bounds = [o[0], o[o.length-1]];

        const d = bounds[0].value - bounds[1].value;

        elements.forEach((e) => (e.normalizedValue = (e.value - bounds[1].value) / d)**2)

        return elements

    }

    return {
        insert

    }

}
