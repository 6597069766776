import { MeshBuilder } from "@babylonjs/core";
import { Color4 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Scene } from "@babylonjs/core/scene";
import { Conn } from "../neurons/domain";
import { thresholdNormalizedBuffer } from "../thresholdBuffer/thresholdNormalizedBuffer";
import { neuronColor } from "../color";

export const lineSystem = (scene: Scene, maxLines:number) => {

    const initArr = []
    const initCol = []

    const buffer=thresholdNormalizedBuffer<Conn>(maxLines)

    const color = new Color4(0.5, 0.5, 0.5, 0.01);
    const color2 = [color, color]

    for (let i = 0; i < maxLines; i++) {
        initArr.push([new Vector3(Math.random(), Math.random(), Math.random()), new Vector3(Math.random(), Math.random(), Math.random())])
        initCol.push(color2)
    }

    let ls = MeshBuilder.CreateLineSystem("lineSystem", {
        lines: initArr,
        colors: initCol,
        updatable: true,
        useVertexAlpha: true,
        instance: null

    }, scene);

    ls.alwaysSelectAsActiveMesh = true
    ls.material.alphaMode = 1;

    return {

        update: (elements) => {

            const connectionsToRender= buffer.insert(elements)

            ls = MeshBuilder.CreateLineSystem("lineSystem", {
                lines: connectionsToRender.map(conn => conn.positions),
                colors: connectionsToRender.map(conn => {


                    const c = (conn.normalizedValue || 0)
                    const c1 = 0.09*c
                    //const color = new Color4(c, c, c, c1);
                    const color = new Color4(1, 1, 1, c*0.2);
                    //const color = neuronColor(c)
                    //color.a=c*0.2;


                    return [color, color]

                }),
                updatable: true,
                useVertexAlpha: true,
                instance: ls

            }, scene);


        }



    }

}