import { MeshBuilder, Sprite, StandardMaterial, Texture, Vector2 } from "@babylonjs/core";
import { SpriteManager } from "@babylonjs/core/Sprites/spriteManager";
import snAsset from "../assets/f2.png";
import digits from "../assets/digits.png";
import { createLayer } from "./layer";
import { Layer, SimilarityType } from "./domain";

import { lineSystem } from "../lineSystem/lineSystem";
import { decodeWeights } from "../weightEncoder/decoder";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";



export const createVis = async (scene, dim: Vector2[]) => {

    const margin = 0.1;

    var plane = MeshBuilder.CreatePlane("plane", {width: 3, height: 0.16}, scene);

    const material = new StandardMaterial("material", scene);
    material.opacityTexture = new Texture(digits, scene);
    material.emissiveTexture = new Texture(digits, scene);


    material.backFaceCulling = false;
    plane.rotation.y = Math.PI;
    plane.position.z = 3;
    plane.position.x = -0.05;
    plane.position.y = -0.03;

    plane.scaling = Vector3.One().scale(0.55);

    plane.material = material

    const spriteManager = new SpriteManager(
        "particleManager",
        snAsset,
        5500,
        52, scene, 0.01);

    //spriteManager.blendMode = 2;

    const ls01 = lineSystem(scene, 400);

    const ls02 = lineSystem(scene, 100);

    const layers: Layer[] = [
        createLayer(dim[0], -1, margin, spriteManager, 255),
        createLayer(dim[1], 1, margin * 1.4, spriteManager),
        createLayer(dim[2], 3, margin * 1.4, spriteManager)
    ]

    await decodeWeights("weights/w100_01", new Vector2(dim[0].x * dim[0].y, dim[1].x * dim[1].y)).then(r => layers[0].setWeights(r));
    await decodeWeights("weights/w100_12", new Vector2(dim[1].x * dim[1].y, dim[2].x * dim[2].y)).then(r => layers[1].setWeights(r));



    let connections01;
    let connections12;


    const update = (dt: number, k: number, d: number, c: number) => {

        if(!connections12 || !connections12) return;
        layers[1].calculateForce(dt,k,d,c);

        ls01.update(connections01)
        ls02.update(connections12)

    }

    const predict = (data: ArrayLike<number>) => {


        layers[0].setValues(data);

        const {connections: lconnections01, values: values01} = layers[0].forwardValues(layers[1])

        connections01=lconnections01
        layers[1].setValues(values01);

        const {connections: lconnections12, values: values02} = layers[1].forwardValues(layers[2])
        connections12=lconnections12
        layers[2].setValues(values02);


        ls01.update(connections01)
        ls02.update(connections12)


    }

    return {
        layers,
        update,
        predict,

        calculateSimilarity:(st:SimilarityType)=>{

            if(st=="dom"){
                layers[1].calculateSimilarityDom(10);

            }

            if(st=="eukl"){
                layers[1].calculateSimilarityEukl(10);

            }




        }


    };

}