import { Vector2 } from "@babylonjs/core";



export const decodeWeights = async (url, dim: Vector2) => {

    const res = await fetch(url)
    const blob = await res.blob();
    const ab = await blob.arrayBuffer();
    const a = new Float32Array(ab);

    const weights: Array<Float32Array> = [];





    for (let i = 0; i < dim.x; i++) {
        weights.push(a.slice(i * dim.y, i * dim.y + dim.y));
    }



    return weights;

}


